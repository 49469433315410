import React from 'react'
import Nav from './Nav'
import Sectionthree from '../Sectionthree'
import SectionFive from './SectionFive'

function Programes() {
  return (
    <div>
        <div className="desktop">
        <Nav/>
        <Sectionthree/>
        <SectionFive/>    
        </div>
    </div>
  )
}

export default Programes